<template>
  <div class="wrap">
    <div class="banner">
      <div class="text animated fadeIn">
        <p class="icon"></p>
        <p class="h2">AI学术课题研究中心</p>
      </div>
    </div>
    <div class="content">
      <div class="en t2"></div>
      <div class="inner product">
        <h2>产品<em>介绍</em></h2>
        <div class="details">
          <div class="img">
            <img src="../../assets/images/emr-text.png"
                 alt="">
          </div>
          <div class="text bg">
            <p>数字病区AI学术课题研究中心， 对病区和医院临床实践实际获得的医疗大数据，通过CDR临床大数据)分析归类与AI存储，可以实现针对医疗领域、护理领域科学研究与学术研讨所需大数据进行分析并提供支持服务，并能通过本系统配备的国际学术期刊与公开发表的研究与大数据库对拟研究课题进行AI评估，从而实现学术研究 与科研成果发表更加前沿、快速与准确。</p>
          </div>
        </div>
      </div>
    </div>
    <div class="content bg">
      <div class="en t1"></div>
      <div class="inner product">
        <h2>产品<em>特征</em></h2>
        <div class="list">
          <ul>
            <li>
              <em class="p1"></em>
              <h3>病例跟踪</h3>
            </li>
            <li>
              <em class="p2"></em>
              <h3>医护沟通</h3>
            </li>
            <li>
              <em class="p3"></em>
              <h3>远程会议</h3>
            </li>
            <li>
              <em class="p4"></em>
              <h3>病理分析</h3>
            </li>
            <li>
              <em class="p5"></em>
              <h3>学术支持</h3>
            </li>
            <li>
              <em class="p6"></em>
              <h3>临床医疗</h3>
            </li>
            <li>
              <em class="p7"></em>
              <h3>远程医疗</h3>
            </li>
            <li>
              <em class="p8"></em>
              <h3>医学影像</h3>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'doctor'
}
</script>

<style lang="scss" scoped>
@import "./css/style.scss";
.banner {
  background-image: url("../../assets/images/banner3.png");
  .icon {
    background-image: url("../../assets/images/s10.png");
  }
}
.content {
  .product .details {
    align-items: center;
    .text {
      padding: 75px 30px;
      display: flex;
      height: auto;
      // align-self: flex-start;
      &.bg {
        background-size: 210px 207px;
      }
    }
    .img {
      // height: 535px;
      background: none;
    }
  }
}
</style>
